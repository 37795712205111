import * as bootstrap from 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
    // Inicializace tooltipů pro informace o ceně
    initTooltips();

    // Funkce pro inicializaci tooltipů
    function initTooltips() {
        const priceInfoElements = document.querySelectorAll('.ticket__price-info');
        priceInfoElements.forEach((element) => {
            const tooltip = bootstrap.Tooltip.getOrCreateInstance(element, {
                container: 'body',
                popperConfig: {
                    modifiers: [
                        {
                            name: 'applyStyles',
                            options: {
                                zIndex: 9999
                            }
                        }
                    ]
                }
            });
        });
    }

    // Inicializace tooltipů po každém kliknutí na stránce (pro případ, že se tooltip přidá dynamicky)
    document.addEventListener('click', () => {
        setTimeout(initTooltips, 100);
    });
});
